import React from 'react';
import Layout from '../../../../components/Layout';
import Seo from '../../../../components/SEO';
import SectionIntro from '../../../../components/SectionIntro';
import {useStaticQuery, graphql} from 'gatsby';
import {getImage} from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image';
import {convertToBgImage} from 'gbimage-bridge';
import BackGlossary from '../../../../components/BackGlossary';
import BannerSimple from '../../../../components/BannerSimple';

const GlossaryPageContent = () => {
  return (
    <div className="md:px-5 max-w-[1285px]">
      <div className="mb-8 text-xl font-bold text-left md:text-2xl">Identity Threat Detection and Response (ITDR)</div>
      <p className="text-lg text-left">
        <b>Embracing ITDR: Finessing Access Management in Today’s Complex Digital Landscape</b>
        <br></br>
        <br></br>In today’s increasingly complex cloud environments, companies are struggling to answer these
        fundamental questions:<br></br>
        <br></br>
      </p>
      <ul className="mb-5 ml-12 text-lg list-disc">
        <li className="mb-5 font-bold">Who are my users?</li>
        <li className="mb-5 font-bold">What can they access?</li>
        <li className="mb-5 font-bold">How did they obtain this access?</li>
        <li className="mb-5 font-bold">Do they need this access, and how often?</li>
        <li className="mb-5 font-bold">When they don’t need this access, do their identities still exist?</li>
      </ul>

      <br></br>
      <p className="text-lg text-left">
        Questions like these are the driving force behind the adoption of Identity Threat Detection and Response (ITDR),
        a crucial component in the realm of Cloud Infrastructure Entitlement Management (CIEM) and access management.
      </p>
      <br></br>
      <p className="text-lg text-left">
        <b>What is ITDR?</b>
        <br></br>
        <br></br>ITDR refers to a proactive security approach that focuses on identifying and responding to threats
        associated with user identities. The primary aim of ITDR is to provide continuous visibility and control over
        identities' privileges and activities, ensuring they align with the principle of least privilege and zero
        standing access. This strategy mitigates the risk of unaudited, unnecessary, accumulated or overextended access
        that could be exploited.
      </p>
      <br></br>
      <p className="text-lg text-left">
        ITDR also detects anomalies and unusual activity related to identities, providing a critical line of defense
        against potential threats.
      </p>
      <br></br>
      <p className="text-lg text-left">
        <b>What are the key elements of an ITDR solution?</b>
        <br></br>
        <br></br>
        Continuous visibility is at the heart of effective ITDR. As organizations manage ever-growing networks of users
        and resources across SaaS and cloud environments, maintaining constant awareness of who has access to what and
        why becomes non-negotiable. ITDR solutions enable this by offering real-time monitoring and analysis of identity
        and access data, providing actionable insights to detect and mitigate potential threats swiftly.
      </p>
      <br></br>
      <p className="text-lg text-left">
        The rapid evolution and increasing sophistication of cyber threats have far surpassed human capacity for
        detection and response. ITDR systems act as force multipliers in this context, leveraging AI and machine
        learning algorithms to detect identity-related anomalies.
      </p>
      <br></br>
      <p className="text-lg text-left">
        While monitoring for identity anomalies makes up a significant part of ITDR, the response - the ability to
        revoke or remediate access automatically - is arguably its most crucial functionality. ITDR is a relatively new
        space. Most platforms within the space have placed all of their eggs in the “Threat Detection” basket, but this
        leaves very little functionality on the response side of the equation.
      </p>
      <br></br>
      <p className="text-lg text-left">
        In today’s world, companies must react in real time. What’s truly needed is a proactive control approach,
        pushing the decision-making process of approval to those who have the relevant context to approve or deny the
        request. This is where Trustle shines the brightest.
      </p>
      <br></br>
      <p className="text-lg text-left">
        <b>The Trustle Solution to ITDR</b>
        <br></br>
        <br></br>
        Trustle, an all-in-one identity threat detection and response system, places a true emphasis on the response
        side of things, to help organizations effectively manage and secure user access to critical data, systems and
        resources. The Trustle system provides nearly instant discovery upon plug in and continues to detect any unusual
        activity across each of your platforms, providing actionable, prioritized items to investigate, instantly
        revoke, or even automate remediation. This automated action accelerates incident response, reduces manual
        effort, and minimizes the window of opportunity for a threat actor to exploit an identity-related vulnerability.
      </p>
      <br></br>
      <p className="text-lg text-left">
        Our customers love the ability to delegate identity users’ access approval and provisioning authority to those
        that actually have the context to approve it within their company (managers, system owners, project leads, etc).
      </p>
      <br></br>
      <p className="text-lg text-left">
        With Trustle, companies can easily implement Zero Standing, least privilege, JIT access (in minutes, not
        months), even leveraging their existing workflow or approval logic. At a glance, companies can see who has
        access to what, and how often their users are actually using their provisioned access (while at the same time
        determining if they are over-privileged, or in a least-privileged state).
      </p>
      <br></br>
      <p className="text-lg text-left">
        In addition to fortifying cybersecurity, Trustle also simplifies compliance management. It provides robust audit
        trails and clear visibility into access privileges, making it significantly easier to demonstrate compliance
        with regulations such as GDPR, CCPA, and HIPAA. Audits are no longer dreaded, as Trustle provides detailed
        analytics, visualization of system entitlement sprawl, and searchable views for each entitlement across each of
        your systems.
      </p>
      <br></br>
      <p className="text-lg text-left">
        Long story short: ITDR is much more than a buzzword in today's complex digital environment; it is a necessary
        measure for proactive access management and enhanced security posture.
      </p>
      <br></br>
      <p className="text-lg text-left">
        As companies strive to answer these questions: <br></br>
        <br></br>
      </p>
      <ul className="mb-5 ml-12 text-lg list-disc">
        <li className="mb-5 font-bold">Who are my users?</li>
        <li className="mb-5 font-bold">What can they access?</li>
        <li className="mb-5 font-bold">How did they obtain this access?</li>
        <li className="mb-5 font-bold">Do they need this access, and how often?</li>
        <li className="mb-5 font-bold"> When they don’t need this access, do their identities still exist?</li>
      </ul>
      <br></br>
      <span className="text-lg">
        <b>Trustle helps provide the answers.</b>
      </span>

      <p className="text-lg text-left">
        <br></br>If you are currently wondering what the answers to the above questions are for your company,{' '}
        <a href="https://www.trustle.com/demo" rel="noreferrer" className="hover:underline text-light-blue">
          click here
        </a>
        {` `}to speak to one of our experts, and find out how quickly we can help you shed light on the answers.
      </p>
    </div>
  );
};

const ItdrIdentityThreatDetectionAndResponse = () => {
  const dataImages = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: {eq: "bg/rebrand/bg-main-top.png"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
        }
      }
    }
  `);
  const {placeholderImage} = dataImages;
  const image = getImage(placeholderImage);
  const bgImage = convertToBgImage(image);
  const title = `ITDR`;
  const description = `Identity Detection and Response`;
  return (
    <Layout seo={<Seo title={`Glossary: ${title} | Trustle`} description={description} />}>
      <BackgroundImage
        Tag="section"
        // Spread bgImage into BackgroundImage:
        {...bgImage}
        preserveStackingContext
        className="bg-cover"
      >
        <section className="">
          <div className="flex flex-col pt-16 mx-5 md:mx-8 lg:px-4 lg:max-w-[1920px] lg:mx-auto ">
            <SectionIntro isPageHeader hero="Glossary" title={title} description={`(${description})`} />
          </div>
        </section>

        <section className="pb-16 ">
          <div className="flex flex-col py-16 mx-5 md:mx-8 lg:px-4 lg:max-w-[1920px] lg:mx-auto">
            <div className=" max-w-[1360px] 3xl:max-w-[1420px] mx-auto text-blue">
              <div className="py-16">
                <BackGlossary />
              </div>
              <GlossaryPageContent />
            </div>
          </div>
          <BannerSimple
            title="Curious how Trustle works?"
            description={'Get an inside look at Trustle. Schedule a demo with us today.'}
          />
        </section>
      </BackgroundImage>
    </Layout>
  );
};

export default ItdrIdentityThreatDetectionAndResponse;
